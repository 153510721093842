import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'S okvirom (vodotijesni/plinotijesni) - Protupožarni moduli | Protupožarna zaštita',
  description: 'Prodori u zidovima i stropovima moraju biti zabrtvljeni tako da su vatrootporni, nepropusni za plin i vodu. Protupožarni moduli su izrađeni od prethodno izrađenog kutnog čeličnog okvira koji se ugrađuje u otvor na zidu ili stropu. Nakon njihove instalacije kabeli/cijevi se mehanički brtve modulima unutar okvira (s mogućnošću prilagodbe promjeru kabela/cijevi). Protupožarni moduli s okvirom se uglavnom koriste u izgradnji građevinarstvu, industriji, moblinoj komunikaciji, tunelima i brodogranji. Protupožarni moduli pogodni kod naknadnog provlačenja instalacija.',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarni-moduli/`, name: 'Protupožarni moduli'},
        { link: `/proizvodi/protupozarni-moduli/s-okvirom/`, name: 'S okvirom (vodotijesni/plinotijesni)'},
      ]}
    />
    <AlternativeHero
      description="Prodori u zidovima i stropovima moraju biti zabrtvljeni tako da su vatrootporni, nepropusni za plin i vodu. Protupožarni moduli su izrađeni od prethodno izrađenog kutnog čeličnog okvira koji se ugrađuje u otvor na zidu ili stropu. Nakon njihove instalacije kabeli/cijevi se mehanički brtve modulima unutar okvira (s mogućnošću prilagodbe promjeru kabela/cijevi). Protupožarni moduli s okvirom se uglavnom koriste u izgradnji građevinarstvu, industriji, moblinoj komunikaciji, tunelima i brodogranji. Protupožarni moduli pogodni kod naknadnog provlačenja instalacija."
      slim
      title="S okvirom (vodotijesni/plinotijesni)"
      subtitle="Proizvodi - Protupožarni moduli"
    />
    <Products activeCategory="s-okvirom" />
  </Page>
)

export default Category